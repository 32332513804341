.header {
  color: #fff;
  .header-nav-li {
    &:nth-child(1) {
      width: 18%;
    }
    &:nth-child(2) {
      width: 18%;
    }
  }
  .img-wrapper {
    width: 20%;
    float: left;
    margin: 0 auto;
  }
  .header-upper {
    background-color: #363434;
    .phone {
      float: left;
      .region {
        color: #cdcdcd;
      }
    }
    .email {
      float: right;
      color: inherit;
    }
    .social-icons {
      display: none;
      float: right;
      .social-icon {
        margin: 0 8px;
        font-size: 16px;
        color: #cdcdcd;
      }
    }
  }
  .nav-wrapper {
    background-color: #2e2b2c;
    height: 62px;

    .main-nav {
      width: 100%;
    }
    .nav {
      list-style-type: none;
      padding: 20px 0;
      width: 100%;
      text-align: center;
      display: table;
      li {
        display: table-cell;
        vertical-align: top;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        margin-left: 17px;
        &.sea{
          position: relative;
          top: -10px;
        }
        &:first-child {
          margin: 0;
        }
        a {
          padding: 0;
          color: inherit;
          background-color: transparent;
          &:hover, &:active{
            background-color: transparent;
          }
        }
      }
    }

    .mobile-nav-wrapper {
      width: 50%;
      position: relative;
      margin: 15px auto;
      .mobile-nav {
        position: absolute;
        width: 100%;
        display: block;
        .mobile-nav-btn {
          font-size: 20px;
          font-weight: 600;
          outline: none !important;
          width: 100%;
          color: black;
          background: white;
          border: none;
          position: relative;
          i {
            font-size: 20px;
            position: absolute;
            right: 6px;
            top: 3px;
          }
        }

        .mobile-nav-dropdown {
          display: none;
          position: relative;
          border: 1px white solid;
          padding: 0;
          margin-top: -2px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          li {
            margin: 0;
            color: black;
            display: block;
            line-height: 40px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .header .nav-wrapper .caribbean-air-sea-shipping-section-link {
    top: -10px !important;
  }
}

@media (max-width: 767px) {
  .header-nav-li {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
  }

  .header .nav-wrapper .mobile-nav-wrapper {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .header {
    .header-upper {
      .email {
        float: left;
        margin-left: 15px;
      }
      .social-icons {
        display: block;
      }
    }
    .enquiry {
      padding-top: 110px;
      background-position: 34%;
      .logo {
        margin-top: 0;
      }
      .section-title {
        margin-top: 22px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .header {
    .nav-wrapper {
      .nav {
        li {
          margin: 0;
        }
      }
    }
    .enquiry {
      background-position: 49%;
      min-height: 462px;
    }

    .caribbean-air-sea-shipping-section-link {
      top: -10px;
    }
  }
}