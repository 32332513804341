.footer {
  text-align: center;
  .logo {
    display: block;
    margin: 0 auto 30px;
  }
  .contact-details {
    padding: 0 6px;
    p {
      margin-bottom: 0;
      span {
        text-transform: uppercase;
        color: red;
        font-size: 16px;
      }
      a {
        color: inherit;
      }
    }
  }
  .social-links {
    margin: 15px 0;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 auto;
      width: 190px;
      .social-link {
        float: left;
        font-size: 20px;
        margin: 0 15px;
        color: #2c3d52;
        a {
          color: inherit;
        }
      }
    }
  }
  .tag-line {
    font-family: 'smudger-let-plain';
    font-size: 42px;
    color: #2c3d52;
  }
  .copyright-information {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    .gml-link {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
    .policy-links {
      list-style-type: none;
      padding: 0;
      height: 17px;
      display: inline-block;
      li {
        float: left;
        margin: 0 9px 0 0;
        &:hover {
          cursor: pointer;
        }
        &:before {
          content: ' | ';
          margin-right: 6px;
        }
        &:first-child {
          margin-left: 9px;
          &:before {
            display: none;
          }
        }
        a {
          color: inherit;
        }
      }
    }
  }
  .policy {
    text-align: left;
    a {
      color: inherit;
    }
    table {
      width: 100%;
      table-layout: fixed;
    }
    thead {
      .column-header {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    tbody {
      td {
        padding-bottom: 10px;
        word-break: break-all;
      }
    }
    .name-column {
      min-width: 130px;
    }
    .party-column {
      min-width: 330px;
    }
    .mobile-cookie {
      .name-column {
        min-width: 130px;
      }
      .purpose-column {
        min-width: 330px;
      }
    }
  }
}

@media (min-width: 1170px) {
  .footer {
    .contact-details {
      p {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}