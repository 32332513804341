@font-face {
  font-family: 'smudger-let-plain';
  font-style: normal;
  font-weight: 400;
  src: local('Smudger LET Plain:1.0'), local('SmudgerLET-Plain'), url(/fonts/smudger-let-plain.woff) format('woff'), url(/fonts/smudger-let-plain.ttf) format('truetype');
}
html,
body {
  font-family: 'Open Sans', serif;
  overflow-x: hidden;
}
.small-text {
  font-size: 12px;
}
.bold {
  font-weight: bold!important;
}
.header {
  color: #fff;
}
.header .header-nav-li:nth-child(1) {
  width: 18%;
}
.header .header-nav-li:nth-child(2) {
  width: 18%;
}
.header .img-wrapper {
  width: 20%;
  float: left;
  margin: 0 auto;
}
.header .header-upper {
  background-color: #363434;
}
.header .header-upper .phone {
  float: left;
}
.header .header-upper .phone .region {
  color: #cdcdcd;
}
.header .header-upper .email {
  float: right;
  color: inherit;
}
.header .header-upper .social-icons {
  display: none;
  float: right;
}
.header .header-upper .social-icons .social-icon {
  margin: 0 8px;
  font-size: 16px;
  color: #cdcdcd;
}
.header .nav-wrapper {
  background-color: #2e2b2c;
  height: 62px;
}
.header .nav-wrapper .main-nav {
  width: 100%;
}
.header .nav-wrapper .nav {
  list-style-type: none;
  padding: 20px 0;
  width: 100%;
  text-align: center;
  display: table;
}
.header .nav-wrapper .nav li {
  display: table-cell;
  vertical-align: top;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-left: 17px;
}
.header .nav-wrapper .nav li.sea {
  position: relative;
  top: -10px;
}
.header .nav-wrapper .nav li:first-child {
  margin: 0;
}
.header .nav-wrapper .nav li a {
  padding: 0;
  color: inherit;
  background-color: transparent;
}
.header .nav-wrapper .nav li a:hover,
.header .nav-wrapper .nav li a:active {
  background-color: transparent;
}
.header .nav-wrapper .mobile-nav-wrapper {
  width: 50%;
  position: relative;
  margin: 15px auto;
}
.header .nav-wrapper .mobile-nav-wrapper .mobile-nav {
  position: absolute;
  width: 100%;
  display: block;
}
.header .nav-wrapper .mobile-nav-wrapper .mobile-nav .mobile-nav-btn {
  font-size: 20px;
  font-weight: 600;
  outline: none !important;
  width: 100%;
  color: black;
  background: white;
  border: none;
  position: relative;
}
.header .nav-wrapper .mobile-nav-wrapper .mobile-nav .mobile-nav-btn i {
  font-size: 20px;
  position: absolute;
  right: 6px;
  top: 3px;
}
.header .nav-wrapper .mobile-nav-wrapper .mobile-nav .mobile-nav-dropdown {
  display: none;
  position: relative;
  border: 1px white solid;
  padding: 0;
  margin-top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.header .nav-wrapper .mobile-nav-wrapper .mobile-nav .mobile-nav-dropdown li {
  margin: 0;
  color: black;
  display: block;
  line-height: 40px;
  font-size: 15px;
}
@media (max-width: 1199px) and (min-width: 992px) {
  .header .nav-wrapper .caribbean-air-sea-shipping-section-link {
    top: -10px !important;
  }
}
@media (max-width: 767px) {
  .header-nav-li {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
  }
  .header .nav-wrapper .mobile-nav-wrapper {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .header .header-upper .email {
    float: left;
    margin-left: 15px;
  }
  .header .header-upper .social-icons {
    display: block;
  }
  .header .enquiry {
    padding-top: 110px;
    background-position: 34%;
  }
  .header .enquiry .logo {
    margin-top: 0;
  }
  .header .enquiry .section-title {
    margin-top: 22px;
  }
}
@media (min-width: 1200px) {
  .header .nav-wrapper .nav li {
    margin: 0;
  }
  .header .enquiry {
    background-position: 49%;
    min-height: 462px;
  }
  .header .caribbean-air-sea-shipping-section-link {
    top: -10px;
  }
}
.footer {
  text-align: center;
}
.footer .logo {
  display: block;
  margin: 0 auto 30px;
}
.footer .contact-details {
  padding: 0 6px;
}
.footer .contact-details p {
  margin-bottom: 0;
}
.footer .contact-details p span {
  text-transform: uppercase;
  color: red;
  font-size: 16px;
}
.footer .contact-details p a {
  color: inherit;
}
.footer .social-links {
  margin: 15px 0;
}
.footer .social-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  width: 190px;
}
.footer .social-links ul .social-link {
  float: left;
  font-size: 20px;
  margin: 0 15px;
  color: #2c3d52;
}
.footer .social-links ul .social-link a {
  color: inherit;
}
.footer .tag-line {
  font-family: 'smudger-let-plain';
  font-size: 42px;
  color: #2c3d52;
}
.footer .copyright-information {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
}
.footer .copyright-information .gml-link {
  color: inherit;
}
.footer .copyright-information .gml-link:hover {
  text-decoration: none;
}
.footer .copyright-information .policy-links {
  list-style-type: none;
  padding: 0;
  height: 17px;
  display: inline-block;
}
.footer .copyright-information .policy-links li {
  float: left;
  margin: 0 9px 0 0;
}
.footer .copyright-information .policy-links li:hover {
  cursor: pointer;
}
.footer .copyright-information .policy-links li:before {
  content: ' | ';
  margin-right: 6px;
}
.footer .copyright-information .policy-links li:first-child {
  margin-left: 9px;
}
.footer .copyright-information .policy-links li:first-child:before {
  display: none;
}
.footer .copyright-information .policy-links li a {
  color: inherit;
}
.footer .policy {
  text-align: left;
}
.footer .policy a {
  color: inherit;
}
.footer .policy table {
  width: 100%;
  table-layout: fixed;
}
.footer .policy thead .column-header {
  text-transform: uppercase;
  font-weight: bold;
}
.footer .policy tbody td {
  padding-bottom: 10px;
  word-break: break-all;
}
.footer .policy .name-column {
  min-width: 130px;
}
.footer .policy .party-column {
  min-width: 330px;
}
.footer .policy .mobile-cookie .name-column {
  min-width: 130px;
}
.footer .policy .mobile-cookie .purpose-column {
  min-width: 330px;
}
@media (min-width: 1170px) {
  .footer .contact-details p {
    display: inline-block;
    margin-right: 10px;
  }
}
.form .form-header {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  font-family: 'Open Sans', serif;
}
.form .form-group .form-input,
.form .form-group .form-select,
.form .form-group .form-submit {
  width: 100%;
  font-size: 12px;
  padding: 7px;
  border-radius: 0;
}
.form .form-group .form-input,
.form .form-group .form-select {
  color: #000;
}
.form .form-group .form-submit {
  background: #ed1c24;
  border: 0;
  color: #fff;
}
.form .form-group .form-submit.blue-submit {
  background: #0b4b79;
}
.form .form-group .form-textarea {
  height: 95px;
}
.form .form-group textarea {
  resize: none;
}
.form .form-group .error {
  font-size: 12px;
  color: #FF0000;
}
.form .form-group.has-error .form-input::-webkit-input-placeholder,
.form .form-group.has-error .form-select::-webkit-input-placeholder,
.form .form-group.has-error .form-select {
  color: #FF0000;
}
.form .form-group.has-error .form-input:-moz-placeholder,
.form .form-group.has-error .form-select:-moz-placeholder,
.form .form-group.has-error .form-select {
  /* Firefox 18- */
  color: #FF0000;
}
.form .form-group.has-error .form-input::-moz-placeholder,
.form .form-group.has-error .form-select::-moz-placeholder,
.form .form-group.has-error .form-select {
  /* Firefox 19+ */
  color: #FF0000;
}
.form .form-group.has-error .form-input:-ms-input-placeholder,
.form .form-group.has-error .form-select:-ms-input-placeholder,
.form .form-group.has-error .form-select {
  color: #FF0000;
}
.form .form-group.has-error .placeholder {
  color: #ff0000;
}
.form .desktop-wrapper {
  padding: 0;
}
.form .marketing-message {
  font-weight: 400;
  font-size: 14px;
}
.form .marketing-message.quick {
  background: rgba(0, 0, 0, 0.5);
  padding: 0 5px;
}
.skittles-bag {
  position: absolute;
  opacity: 0.01;
  height: 1px;
  width: 1px;
  border: 0;
  bottom: 0;
  left: 0;
}
.grecaptcha-badge {
  display: none !important;
}
@media (min-width: 991px) {
  .form .form-group.textarea-wrapper {
    margin-bottom: 12px;
  }
  .form .form-group.textarea-wrapper .form-textarea {
    height: 185px;
  }
}
@media (min-width: 1170px) {
  .quote-request .quote-request-header .section-title {
    float: left;
  }
  .quote-request .quote-request-header p {
    float: left;
    margin-top: 25px;
    margin-left: 10px;
  }
}
.section.header-section {
  color: white;
}
.section.header-section.margin-bottom {
  margin-bottom: 40px;
}
.section.enquiry {
  color: white;
  background: url('/img/banner.jpg');
  background-size: cover;
  background-position: 40%;
}
.section.enquiry.no-form {
  padding-top: 504px;
}
.section.enquiry .logo {
  display: block;
  margin: 10px auto 0;
}
.section.policy h2,
.section.policy h3,
.section.policy .section-title {
  font-weight: 700;
  font-size: 24px;
  font-family: 'Roboto Slab', serif;
}
.section .section-title {
  font-weight: 700;
  font-size: 24px;
  font-family: 'Roboto Slab', serif;
  margin-top: 10px;
}
.section.page-section {
  padding: 30px 0;
}
.section.page-section:nth-child(even) {
  background: #f4f4f4;
}
.section.header-section {
  text-align: center;
  padding: 15px;
}
.section.header-section p {
  font-family: 'Roboto Slab', serif;
  margin: 0;
  font-size: 15px;
  font-weight: bold;
}
.section.air-sea-shipping-section img {
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
  display: block;
}
.section.air-sea-shipping-section h2.section-title {
  margin-bottom: 25px;
  font-size: 24px;
}
.section.air-sea-shipping-section h3.section-title {
  margin-bottom: 10px;
  font-size: 24px;
}
.section.air-sea-shipping-section p {
  margin-bottom: 25px;
}
.section.blue-section {
  background-color: #0b4b79;
}
.section.red-section {
  background-color: #ed1c24;
}
.section.red-section.header-section p {
  font-family: 'Open Sans', serif;
  font-weight: 400;
}
.section.red-section.header-section p .large-text {
  font-family: 'Roboto Slab', serif;
}
.section.black-section {
  background: #2e2b2b;
  color: #fff;
}
.section.enquiry .section-title {
  font-size: 28px;
  text-align: center;
}
#contact-section h2 {
  margin-top: 0 !important;
}
@media (min-width: 768px) {
  .section.enquiry {
    padding-top: 110px;
    background-position: 34%;
  }
  .section.header-section p {
    font-size: 24px;
  }
  .section .large-text {
    font-size: 32px;
  }
}
@media (min-width: 1170px) {
  .section.header-section p {
    font-size: 32px;
  }
}
.cargo-tracking {
  padding: 35px 25px;
  font-weight: 400;
  font-size: 14px;
}
.cargo-tracking h2 {
  margin: 0 0 20px;
  font-family: 'Roboto Slab', serif;
}
.cargo-tracking span {
  font-weight: 700;
}
.cargo-tracking .tracking-input {
  width: 180px;
  font-size: 12px;
  font-style: italic;
  padding: 7px;
  float: left;
  height: 35px;
  border: 0;
  color: #000;
}
.cargo-tracking .tracking-submit {
  padding: 7px 8px 8px;
  background: #ed1c24;
  color: #fff;
  width: 80px;
  border: 0;
  height: 35px;
}
@media (min-width: 768px) {
  .cargo-tracking {
    padding: 35px 16px;
    margin-top: 48px;
  }
}
@media (min-width: 991px) {
  .cargo-tracking {
    margin-top: 25px;
  }
}
@media (min-width: 1170px) {
  .cargo-tracking {
    padding: 35px 28px;
    margin-top: 0;
  }
  .cargo-tracking .tracking-input {
    width: 230px;
  }
}
.service-benefits .service-benefit {
  margin: 15px 0;
}
.service-benefits .service-benefit .service-image {
  text-align: center;
  padding-top: 5px;
}
.service-benefits .service-benefit .service-content {
  font-size: 16px;
}
.service-benefits .service-benefit .service-content .name {
  margin: 0;
  font-family: 'Roboto Slab', serif;
  font-size: 24px;
  font-weight: bold;
}
.services .service {
  border: 1px solid #ebebeb;
  background: #fff;
  margin-bottom: 8px;
  padding: 0;
}
.services .service .service-content {
  padding: 15px 9px;
}
.services .service .service-content .title {
  font-family: 'Roboto Slab', serif;
  margin: 0 0 30px;
  font-weight: bold;
}
.services .service .service-content .content {
  color: #727272;
  margin: 0 0 20px;
  font-size: 16px;
}
.services .service .service-image {
  padding: 0;
}
.services .service .service-image img {
  width: 100%;
}
.services .quote-link {
  color: #9d9d9d;
}
.services .quote-link:hover {
  text-decoration: none;
  cursor: pointer;
}
@media (min-width: 768px) {
  .service-benefits .service-benefit {
    padding: 0;
    min-height: 70px;
  }
  .service-benefits .service-benefit .service-image {
    text-align: left;
  }
  .services .service .service-content {
    padding: 25px 30px;
  }
}
@media (min-width: 1170px) {
  .service-benefits {
    border-top: 1px solid #ebebeb;
  }
  .service-benefits .service-benefit {
    border-bottom: 1px solid #ebebeb;
  }
  .services .service {
    margin-bottom: 30px;
  }
  .services .service .service-content {
    padding: 25px 10px;
  }
}
.map {
  height: 350px;
}
.policy {
  display: none;
}
@media (min-width: 768px) {
  body {
    font-size: 16px;
  }
  .container {
    padding: 0;
  }
}
