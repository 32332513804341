.section {
  &.header-section{
    color: white;
    &.margin-bottom{
      margin-bottom: 40px;
    }
  }
  &.enquiry {
    &.no-form{
      padding-top: 504px;
    }
    color:white;
    background: url('/img/banner.jpg');
    background-size: cover;
    background-position: 40%;
    .logo {
      display: block;
      margin: 10px auto 0;
    }
  }
  &.policy {
    h2,
    h3,
    .section-title {
      font-weight: 700;
      font-size: 24px;
      font-family: @alt-font;
    }
  }
  .section-title {
    font-weight: 700;
    font-size: 24px;
    font-family: @alt-font;
    margin-top: 10px;
  }
  &.page-section {
    padding: 30px 0;
    &:nth-child(even) {
      background: #f4f4f4;
    }
  }
  &.header-section {
    text-align: center;
    padding: 15px;
    p {
      font-family: @alt-font;
      margin: 0;
      font-size: 15px;
      font-weight: bold;
    }
  }
  &.air-sea-shipping-section {
    img {
        width: 100%;
        max-width: 370px;
        margin: 0 auto;
        display: block;
    }
    h2.section-title {
        margin-bottom: 25px;
        font-size: 24px;
    }
    h3.section-title {
        margin-bottom: 10px;
        font-size: 24px;
    }
    p {
        margin-bottom: 25px;
    }
  }
  &.blue-section {
    background-color: #0b4b79;
  }
  &.red-section {
    background-color: #ed1c24;
    &.header-section {
      p {
        font-family: @main-font;
        font-weight: 400;
        .large-text {
          font-family: @alt-font;
        }
      }
    }
  }
  &.black-section {
    background: #2e2b2b;
    color: #fff;
  }
  &.enquiry {
    .section-title {
      font-size: 28px;
      text-align: center;
    }
  }
}

#contact-section {
  h2 {
    margin-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .section {
    &.enquiry{
      padding-top: 110px;
      background-position: 34%;
    }
    &.header-section {
      p {
        font-size: 24px;
      }
    }
    .large-text {
      font-size: 32px;
    }
  }
}
@media (min-width: 1170px) {
  .section {
    &.header-section {
      p {
        font-size: 32px;
      }
    }
  }
}