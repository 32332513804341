.service-benefits {
  .service-benefit {
    margin: 15px 0;
    .service-image {
      text-align: center;
      padding-top: 5px;
    }
    .service-content {
      font-size: 16px;
      .name {
        margin: 0;
        font-family: @alt-font;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
}

.services {
  .service {
    border: 1px solid #ebebeb;
    background: #fff;
    margin-bottom: 8px;
    padding: 0;
    .service-content {
      padding: 15px 9px;
      .title {
        font-family: @alt-font;
        margin: 0 0 30px;
        font-weight: bold;
      }
      .content {
        color: #727272;
        margin: 0 0 20px;
        font-size: 16px;
      }
    }
    .service-image {
      padding: 0;
      img {
        width: 100%;
      }
    }
  }
  .quote-link {
    color: #9d9d9d;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media (min-width: 768px) {
  .service-benefits {
    .service-benefit {
      padding: 0;
      min-height: 70px;
      .service-image {
        text-align: left;
      }
    }
  }
  .services {
    .service {
      .service-content {
        padding: 25px 30px;
      }
    }
  }
}


@media (min-width: 1170px) {
  .service-benefits {
    border-top: 1px solid #ebebeb;
    .service-benefit {
      border-bottom: 1px solid #ebebeb;
    }
  }
  .services {
    .service {
      margin-bottom: 30px;
      .service-content {
        padding: 25px 10px;
      }
    }
  }
}