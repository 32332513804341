@import "variables.less";

@font-face {
  font-family: 'smudger-let-plain';
  font-style: normal;
  font-weight: 400;
  src: local('Smudger LET Plain:1.0'), local('SmudgerLET-Plain'),
  url(/fonts/smudger-let-plain.woff) format('woff'),
  url(/fonts/smudger-let-plain.ttf) format('truetype');
}

html, body {
  font-family: @main-font;
  overflow-x: hidden;
}

.small-text {
  font-size: 12px;
}

.bold {
  font-weight: bold!important;
}

@import "components.less";

@media(min-width: 768px) {
  body {
    font-size: 16px;
  }
  .container{
    padding: 0;
  }
}