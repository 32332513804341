.cargo-tracking {
  padding: 35px 25px;
  font-weight: 400;
  font-size: 14px;
  h2 {
    margin: 0 0 20px;
    font-family: @alt-font;
  }
  span {
    font-weight: 700;
  }
  .tracking-input {
    width: 180px;
    font-size: 12px;
    font-style: italic;
    padding: 7px;
    float: left;
    height: 35px;
    border: 0;
    color: #000;
  }
  .tracking-submit {
    padding: 7px 8px 8px;
    background: #ed1c24;
    color: #fff;
    width: 80px;
    border: 0;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .cargo-tracking {
    padding: 35px 16px;
    margin-top: 48px;
  }
}
@media (min-width: 991px) {
  .cargo-tracking {
    margin-top: 25px;
  }
}
@media (min-width: 1170px) {
  .cargo-tracking {
    padding: 35px 28px;
    margin-top: 0;
    .tracking-input {
      width: 230px;
    }
  }
}