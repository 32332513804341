.form {
  .form-header {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    font-family: @main-font;
  }
  .form-group {
    .form-input,
    .form-select,
    .form-submit {
      width: 100%;
      font-size: 12px;
      padding: 7px;
      border-radius: 0;
    }
    .form-input,
    .form-select {
      color: #000;
    }
    .form-submit {
      background: #ed1c24;
      border: 0;
      color: #fff;
      &.blue-submit {
        background: #0b4b79;
      }
    }
    .form-textarea {
      height: 95px;
    }
    textarea {
      resize: none;
    }
    .error {
      font-size: 12px;
      color: #FF0000;
    }
    &.has-error {
      .form-input::-webkit-input-placeholder,
      .form-select::-webkit-input-placeholder,
      .form-select {
        color: #FF0000;
      }

      .form-input:-moz-placeholder,
      .form-select:-moz-placeholder,
      .form-select { /* Firefox 18- */
        color: #FF0000;
      }

      .form-input::-moz-placeholder,
      .form-select::-moz-placeholder,
      .form-select {  /* Firefox 19+ */
        color: #FF0000;
      }

      .form-input:-ms-input-placeholder,
      .form-select:-ms-input-placeholder,
      .form-select {
        color: #FF0000;
      }
      .placeholder {
        color: #ff0000;
      }
    }
  }
  .desktop-wrapper {
    padding: 0;
  }
  .marketing-message {
    font-weight: 400;
    font-size: 14px;

    &.quick {
      background: rgba(0, 0, 0, 0.5);
      padding: 0 5px;
    }
  }
}
.skittles-bag {
  position: absolute;
  opacity: 0.01;
  height: 1px;
  width: 1px;
  border: 0;
  bottom: 0;
  left: 0;
}
.grecaptcha-badge {
  display: none !important;
}

@media (min-width: 768px) {
  .form {
    //margin: 0 -10px;
  }
}

@media (min-width: 991px) {
  .form {
    .form-group {
      &.textarea-wrapper {
        margin-bottom: 12px;
        .form-textarea {
          height: 185px;
        }
      }
    }
  }
}

@media (min-width: 1170px) {
  .quote-request {
    .quote-request-header {
      .section-title {
        float: left;
      }
      p {
        float: left;
        margin-top: 25px;
        margin-left: 10px;
      }
    }
  }
}